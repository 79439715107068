<template>
  <div class="pa-10">



    <title-page icon="ico-price-list">
      {{ $t('manage-tariffs') }}
    </title-page>


    <h1>
      Comment gérer ma tarification ?
    </h1>


    <p class="py-6">
      Conseil ZEborne !<br />
      Visionnez cette vidéo et consultez le guide utilisateur ainsi que la grille tarifaire pour vous aider à mettre en place une tarification sur vos points de charge.
    </p>

    <v-row>
      <v-col>

        <div class="player" data-plyr-provider="youtube" data-plyr-embed-id="3C0p4UtoOSc"></div>

        <div class="text-center mt-4" style="width: 800px;">
          <v-btn href="/assets/docs/guide utilisateur zeborne.pdf" target="_blank" class="mr-10" color="primary">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Mon guide utilisateur ZEborne
          </v-btn>
          <v-btn href="/assets/docs/Grille tarifaire - CPO ZEborne.pdf" target="_blank" color="primary">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Ma grille tarifaire ZEborne
          </v-btn>
        </div>

      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  mounted() {
    const player = new window.Plyr(this.$el.querySelector('.player'), {
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
    });
  }
}
</script>

<style lang="scss">
  :root {
    --plyr-color-main: #e53c22;
    --plyr-video-background: #e53c22;
  }
  .plyr {
    color: white;
    max-width: 800px;
    .plyr__video-embed {
      iframe {
        top: -50%;
        height: 200%;
      }
    }
  }


</style>